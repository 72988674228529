import React from "react"
import { Box, Button, Heading, Text, useTheme } from "@chakra-ui/core"

import Layout from "../components/layout"

const Kiitos = props => {
  const theme = useTheme()
  return (
    <Layout>
      <Box w="100%" textAlign="center" p="6">
        <Box maxW={theme.breakpoints.lg} mx="auto">
          <Heading color="pink.800" mb="4">
            Kiitos viestistäsi!
          </Heading>
          <Text color="pink.800" fontSize="lg" mb="6">
            Olemme tarvittaessa yhteyksissä mahdollisimman pian.
          </Text>
        </Box>
      </Box>
    </Layout>
  )
}

export default Kiitos
